var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"light":"","persistent":"","max-width":"560px"},model:{value:(_vm.providersDialog),callback:function ($$v) {_vm.providersDialog=$$v},expression:"providersDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline px-2"},[_c('v-app-bar',{attrs:{"flat":"","color":"rgba(0, 0, 0, 0)"}},[_c('v-toolbar-title',{staticClass:"text-h5 pl-0"},[_vm._v(" "+_vm._s(this.formData.id ? 'Editar aplicação' : 'Nova aplicação')+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"mt-2",attrs:{"color":"red","fab":"","icon":"","dark":"","x-small":"","absolute":"","top":"","right":""},on:{"click":_vm.toogleRegisterProvidersDilog}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"providerForm",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"label":"Nome do serviço","rules":_vm.$store.state.formRules.required},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',[_c('v-text-field-simplemask',{attrs:{"label":"CNPJ","properties":{
                              rules: [
                                _vm.$store.state.formRules.cpfCnpjRules[0](
                                  _vm.formData.cnpj ? _vm.formData.cnpj : ''
                                ) ],
                              prefix: '',
                              suffix: '',
                              readonly: false,
                              disabled: false,
                              outlined: false,
                              clearable: false,
                              placeholder: '',
                            },"options":{
                              inputMask:'##.###.###/####-##',
                              outputMask: '##############',
                              empty: null,
                              applyAfter: false,
                              alphanumeric: true,
                              lowerCase: false,
                            }},model:{value:(_vm.formData.cnpj),callback:function ($$v) {_vm.$set(_vm.formData, "cnpj", $$v)},expression:"formData.cnpj"}})],1)]),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Informação adicional"},model:{value:(_vm.formData.additional_information),callback:function ($$v) {_vm.$set(_vm.formData, "additional_information", $$v)},expression:"formData.additional_information"}})],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"px-0 mx-3",staticStyle:{"border-top":"1px solid rgb(220, 220, 220)"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.toogleRegisterProvidersDilog}},[_vm._v(" Fechar ")]),_c('v-btn',{attrs:{"color":"green","text":""},on:{"click":_vm.saveProviders}},[_vm._v(" Salvar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
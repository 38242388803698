<template>
  <v-row justify="center">
    <v-dialog light v-model="providersDialog" persistent max-width="560px">
      <v-card>
        <v-card-title class="headline px-2">
          <v-app-bar flat color="rgba(0, 0, 0, 0)">
            <v-toolbar-title class="text-h5 pl-0">
              {{this.formData.id ? 'Editar aplicação' : 'Nova aplicação'}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- <v-fab-transition> -->
            <v-btn
              @click="toogleRegisterProvidersDilog"
              class="mt-2"
              color="red"
              fab
              icon
              dark
              x-small
              absolute
              top
              right
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <!-- </v-fab-transition> -->
          </v-app-bar>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="providerForm" v-model="valid">
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="formData.name"
                    label="Nome do serviço"
                    :rules="$store.state.formRules.required"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <div>
                            <v-text-field-simplemask
                              v-model="formData.cnpj"
                              v-bind:label="`CNPJ`"
                              v-bind:properties="{
                                rules: [
                                  $store.state.formRules.cpfCnpjRules[0](
                                    formData.cnpj ? formData.cnpj : ''
                                  ),
                                ],
                                prefix: '',
                                suffix: '',
                                readonly: false,
                                disabled: false,
                                outlined: false,
                                clearable: false,
                                placeholder: '',
                              }"
                              v-bind:options="{
                                inputMask:'##.###.###/####-##',
                                outputMask: '##############',
                                empty: null,
                                applyAfter: false,
                                alphanumeric: true,
                                lowerCase: false,
                              }"
                            />
                          </div>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="formData.additional_information"
                    label="Informação adicional"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions
          class="px-0 mx-3"
          style="border-top: 1px solid rgb(220, 220, 220)"
        >
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="toogleRegisterProvidersDilog">
            Fechar
          </v-btn>
          <v-btn color="green" text @click="saveProviders"> Salvar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
const resetProvidersForm = {
  name: "",
  cnpj: "",
  additional_information: "",
};
export default {
  methods: {
    saveProviders() {
      //console.log("servicsave");
      if (!this.$refs.providerForm.validate()) {
        return;
      }

      let url = this.formData.id
        ? "/api/provider/update"
        : "/api/provider/create";
      // this.formData.company_id = this.selectedCompany.id;
      var obj = {
        url: url,
        query: this.formData,
        method: "post",
      };
      //console.log(obj);
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        let opts = {
          message: `Aplicação ${
            this.formData.id ? "atualizada" : "criada"
          } com sucesso`,
          type: "success",
        };
        this.$store.commit("showAlert", opts);
        this.updateProviders(response.data);
        this.toogleRegisterProvidersDilog();
      });
    },
  },
  watch: {
    providersDialog(v) {
      if (!v) {
        this.$refs.providerForm.resetValidation();
        this.formData = { ...resetProvidersForm };
      } else {
        if (this.editProvidersData) {
          this.formData = this.editProvidersData;
        }
      }
    },
  },
  mounted() {},
  props: {
    updateProviders: Function,
    editProvidersData: Object,
    toogleRegisterProvidersDilog: Function,
    providersDialog: Boolean,
  },
  data() {
    return {
      companies: [],
      selectedCompany: null,
      valid: true,
      formData: { ...resetProvidersForm },
    };
  },
};
</script>

<style>
</style>
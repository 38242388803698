<template>
  <div class="pl-16 pr-2">
    <v-card
      :min-height="windowH"
      class="mx-auto mt-3 mb-9 bgTransparente"
      max-width="calc(100% - 10px)"
      elevation="0"
    >
      <v-card-title class="ml-15 pt-8 pl-0 black--text"
        >Aplicações cadastradas</v-card-title
      >
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="toogleRegisterProvidersDilog"
            v-bind="attrs"
            v-on="on"
            class="mt-13 mr-10"
            color="primary"
            fab
            dark
            :fixed="responsiveSize < 500"
            :absolute="responsiveSize >= 500"
            :bottom="responsiveSize < 500"
            :top="responsiveSize >= 500"
            right
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Adicionar aplicação</span>
      </v-tooltip>
      <v-data-table
        light
        :headers="headers"
        :items="providers"
        :items-per-page="10"
        class="elevation-0 opacityTable mx-15"
      >
        <template v-slot:item.cnpj="{ item }">
          {{ item.cnpj | formatCnpjCpf }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="btMicro px-1 mr-3"
                @click="editProviders(item)"
                x-small
                color="primary"
                dark
                ><v-icon center x-small> edit </v-icon></v-btn
              >
            </template>
            <span class="miniTtip">Editar aplicação</span>
          </v-tooltip>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="btMicro px-1"
                @click="confirmDelProviders(item)"
                x-small
                color="red"
                dark
                ><v-icon center x-small> mdi-delete-forever </v-icon></v-btn
              >
            </template>
            <span class="miniTtip">Excluir aplicação</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <RegisterProviders
      :updateProviders="updateProviders"
      :editProvidersData="editProvidersData"
      :toogleRegisterProvidersDilog="toogleRegisterProvidersDilog"
      :providersDialog="providersDialog"
    />
  </div>
</template>

<script>
import RegisterProviders from "./RegisterProviders.vue";
import MyFilters from "../../filters/genericFilters";
export default {
  computed: {
    responsiveSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
  },
  beforeMount() {
    this.getHeightPage();
  },
  mounted() {
    this.getProviders();
  },
  methods: {
    async getProvidersById(id) {
      let line;
      var obj = {
        url: "/provider/getById",
        query: { id: id },
        method: "post",
      };
      await this.$store.dispatch("request/doRequest", obj).then((response) => {
        line = response.data[0];
      });
      return line;
    },
    updateProviders(id) {
      this.getProviders();
      // if (id) {
      //   this.getProvidersById(id).then((line) => {
      //     let index = this.Providers.findIndex((providers) => providers.id === id);
      //     this.providers.splice(index, 1);
      //     this.providers.splice(index, 0, line);
      //   });
      // }
    },
    editProviders(data) {
      this.editProvidersData = { ...data };
      this.providersDialog = true;
    },
    confirmDelProviders(data) {
      let obj = {
        message: `Você deseja excluir a aplicação <b>${data.name}</b>?`,
        title: "Excluir aplicação",
        callback: (_) => {
          this.delProviders(data);
        },
        type: "warning",
        btConfirm: { name: "Sim", show: true },
        btCancel: { name: "Não", show: true },
      };
      this.$store.commit("confirmMessage/showConfirmMessage", obj);
    },
    delProviders(data) {
      let obj = {
        url: "/api/provider/delete",
        query: { id: data.id },
        method: "post",
      };
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        let index = this.providers.findIndex(
          (providers) => providers.id === data.id
        );
        this.Providers.splice(index, 1);
        let opts = {
          message: `Aplicação <b>${data.name}</b> removida com sucesso.`,
          type: "success",
        };
        this.$store.commit("showAlert", opts);
      });
    },
    toogleRegisterProvidersDilog() {
      this.editProvidersData = null;
      this.providersDialog = !this.providersDialog;
    },
    getHeightPage() {
      this.windowH = window.innerHeight - 125;
    },
    getProviders() {
      var obj = {
        url: "/api/provider/get-all",
        query: null,
        method: "get",
      };
      //console.log(obj);
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        // console.log(response);
        this.providers = [...response.data];
      });
    },
  },
  created() {
    window.addEventListener("resize", this.getHeightPage);
  },
  destroyed() {
    window.removeEventListener("resize", this.getHeightPage);
  },
  data() {
    return {
      windowH: "580",
      editProvidersData: null,
      providersDialog: false,
      headers: [
        { text: "Nome", value: "name", sortable: true },
        { text: "CNPJ", value: "cnpj", sortable: false },
        {
          text: "Informação adicional",
          value: "additional_information",
          sortable: false,
        },
        { text: "Ações", value: "actions", sortable: false },
      ],
      providers: [],
    };
  },
  components: {
    RegisterProviders,
  },
};
</script>

<style>
</style>